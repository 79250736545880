import React from 'react';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { EVET_HAYIR } from '../types';

const moneyFormatter = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', minimumFractionDigits: 0, maximumFractionDigits: 0 });
const numberFormattter = new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

export const yesNoRender = (text) => {
  if(text === EVET_HAYIR.EVET) {
    return <Tag className="w-12 text-center" color="green">Evet</Tag>
  }

  return <Tag className="w-12 text-center" color="red">Hayır</Tag>
}

export const moneyColumnRender = (text: number) => {
  return moneyFormat(text);
};

export const moneyFormat = (value: number | undefined) => {
  if (value) {
    return moneyFormatter.format(value);
  }

  return '';
};

export const numberFormat = (value: number | undefined) => {
  if (value) {
    return numberFormattter.format(value);
  }

  return '';
};


export const dateFormat = (date: string | undefined) => {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('DD/MM/YYYY');
  }
  return '';
};

export const monthFormat = (date: string | undefined) => {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('MM/YYYY');
  }
  return '';
};
