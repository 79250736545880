import { PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import { message } from 'antd';

import * as API from '../../api';
import { DatePicker, DictFormActions, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm } from '../../hooks';
import { ROL, SiparisModel } from '../../types';

const OrderForm = z.object({
  kullaniciId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  pazaryeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  kategoriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  markaId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  urunId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  depoyeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  adet: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, "Adet 1'den büyük olmalı."),
  alisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).gt(0, "Fiyat 0'dan büyük olmalı."),
  siparisNo: z.string().min(1, 'Zorunludur'),
  tedarikciOdenecekTarih: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciAciklama: z.string().optional(),
  adminAciklama: z.string().optional(),
});

export const NewOrder = ({ onSaveDone = () => null, fillOrder }: { onSaveDone?: CallableFunction; fillOrder?: SiparisModel | null }) => {
  const kullaniciModel = useContext(UserContext);
  const isAdmin = kullaniciModel.kullaniciTipi === ROL.ADMIN;

  const [disabled, setDisabled] = useState(false);

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehousesTed = []] = API.WAREHOUSES.useFetchActiveTed({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, clear, fill } = useFillForm(OrderForm, {
    kullaniciId: kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId,
    pazaryeriId: undefined,
    markaId: undefined,
    kategoriId: undefined,
    urunId: undefined,
    depoyeriId: undefined,
    adet: 1,
    alisFiyat: undefined,
    siparisNo: '',
    tedarikciOdenecekTarih: new Date(),
    siparisTarihi: new Date(),
    tedarikciAciklama: '',
    adminAciklama: '',
  });

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  useEffect(() => {
    clear();
    if (fillOrder) {
      fill({
        pazaryeriId: fillOrder.bhrPazaryeriModel?.pazaryeriId,
        kategoriId: fillOrder.bhrKategoriModel?.kategoriId,
        markaId: fillOrder.bhrMarkaModel?.markaId,
        urunId: fillOrder.bhrUrunModel?.urunId,
        depoyeriId: fillOrder.bhrDepoyeriModel?.depoyeriId,
        alisFiyat: fillOrder.alisFiyat,
        tedarikciAciklama: '',
        //tedarikciAciklama: fillOrder.tedarikciAciklama || '',
        //tedarikciOdenecekTarih: new Date(),
        siparisTarihi: new Date(),
        tedarikciOdenecekTarih: fillOrder.tedarikciOdenecekTarih ? new Date(fillOrder.tedarikciOdenecekTarih) : new Date(),
        //siparisTarihi: fillOrder.siparisTarihi ? new Date(fillOrder.siparisTarihi) : new Date(),
        adet: 1,
      });
    }
  }, [fillOrder?.siparisId]);

  const seriNoRange = Array.from({ length: Number(form.value.adet || 0) }).fill(0);

  const productByCategory = products.filter((product) => product.bhrKategoriModel?.kategoriId === form.value.kategoriId && product.bhrMarkaModel?.markaId === form.value.markaId);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      setDisabled(true);
      const tedarikciOdenecekTarih = new Date(data.tedarikciOdenecekTarih);
      tedarikciOdenecekTarih.setHours(0);
      tedarikciOdenecekTarih.setMinutes(0);
      tedarikciOdenecekTarih.setSeconds(0);
      tedarikciOdenecekTarih.setMilliseconds(0);

      const siparisTarihi = new Date(data.siparisTarihi);
      siparisTarihi.setHours(0);
      siparisTarihi.setMinutes(0);
      siparisTarihi.setSeconds(0);
      siparisTarihi.setMilliseconds(0);
      const formedData = {
        bhrPazaryeriModel: {
          pazaryeriId: data.pazaryeriId,
        },
        bhrKategoriModel: {
          kategoriId: data.kategoriId,
        },
        bhrMarkaModel: {
          markaId: data.markaId,
        },
        bhrUrunModel: {
          urunId: data.urunId,
        },
        bhrDepoyeriModel: {
          depoyeriId: data.depoyeriId,
        },
        adet: data.adet,
        alisFiyat: data.alisFiyat,
        siparisNo: (data.siparisNo as any).replaceAll(' ', '') as string,
        tedarikciOdenecekTarih: tedarikciOdenecekTarih.toISOString(),
        siparisTarihi: siparisTarihi.toISOString(),
        tedarikciAciklama: data.tedarikciAciklama,
        adminAciklama: data.adminAciklama,
        firmaModel: kullaniciModel.firmaModel,
        kullaniciModel: {
          kullaniciId: data.kullaniciId,
        },
      };

      const saveArray: SiparisModel[] = [];
      for (let i = 0; i < seriNoRange.length; i++) {
        const seriNo = form.value['seriNo_' + i];
        const saveData = {
          ...formedData,
          seriNo: seriNo,
        };

        saveArray.push(saveData);
      }

      await API.ORDERS.save(saveArray);


      onSaveDone();
      clear();

      setDisabled(false);

    } else {
      form.setAllTouched();
    }
  }, [form, setDisabled]);

  return (
    <div className="flex flex-col gap-2">
      <Card title="Yeni Sipariş Ekleme">
        <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
          {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
            <>
              <label className="whitespace-nowrap">Tedarikçi :</label>
              <Select
                options={users.map((user) => ({ ...user, label: `${user.kullaniciAdi} - ${user.ad} ${user.soyad}` }))}
                fieldNames={{ label: 'label', value: 'kullaniciId' }}
                form={form}
                name="kullaniciId"
              />
            </>
          )}

          <label className="whitespace-nowrap">Pazaryeri :</label>
          <Select options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={form} name="pazaryeriId" />

          <label className="whitespace-nowrap">Kategori :</label>
          <Select options={categories} fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }} form={form} name="kategoriId" />

          <label className="whitespace-nowrap">Marka :</label>
          <Select options={brands} fieldNames={{ label: 'markaAdi', value: 'markaId' }} form={form} name="markaId" />

          <label className="whitespace-nowrap">Ürün :</label>
          <Select
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            searchFields={['barkodNo']}
            placeholder="Ürün Adı veya Barkod"
            form={form}
            name="urunId"
          />

          <label className="whitespace-nowrap">Depoyeri :</label>
          <Select options={isAdmin ? warehouses : warehousesTed} fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }} form={form} name="depoyeriId" />

          <label className="whitespace-nowrap">Adet :</label>
          <InputNumber form={form} name="adet" min={1} />

          {seriNoRange.map((_, index) => (
            <Fragment key={'seri_' + index}>
              <label className="whitespace-nowrap">Seri No {index + 1} :</label>
              <Input form={form} name={'seriNo_' + index} />
            </Fragment>
          ))}

          <label className="whitespace-nowrap">Alış Fiyat :</label>
          <InputNumber form={form} name="alisFiyat" min={0} />

          <label className="whitespace-nowrap">Sipariş No :</label>
          <Input form={form} name="siparisNo" />

          <label className="whitespace-nowrap">Ödenecek Tarih :</label>
          <DatePicker form={form} name="tedarikciOdenecekTarih" />

          <label className="whitespace-nowrap">Sipariş Tarih :</label>
          <DatePicker form={form} name="siparisTarihi" />

          <label className="whitespace-nowrap">Açiklama :</label>
          <Input form={form} name="tedarikciAciklama" />

          {isAdmin && (
            <>
              <label className="whitespace-nowrap">Admin Açiklama :</label>
              <Input form={form} name="adminAciklama" />
            </>
          )}

          <DictFormActions save={save} clear={clear} disabled={disabled} />
        </div>
      </Card>
    </div>
  );
};

NewOrder.path = 'new-order';
NewOrder.title = 'Yeni Sipariş';
NewOrder.group = 'supplier';
NewOrder.roles = [ROL.TEDARIKCI, ROL.ADMIN];
NewOrder.icon = <PlusCircleOutlined />;
