import { ColumnsType } from 'antd/es/table';
import { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { AKTIF_PASIF, ROL, SiparisModel, SIPARIS_STATU, EVET_HAYIR, DokumanModel } from '../types';

import { createOrderColumns } from '../utils';
import { useMultiSelect } from './useMultiSelect';
import { useSingleSelect } from './useSingleSelect';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { downloadFile } from '../api/files';

export const useOrderList = (faturaBekleyenler = false, onayBekleyenler = false, sahipsizler = false) => {
  const kullaniciModel = useContext(UserContext);
  const isAdmin = kullaniciModel.kullaniciTipi === ROL.ADMIN;

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId);
  const [siparisStatu, setSiparisStatu] = useState<SIPARIS_STATU | undefined>(SIPARIS_STATU.SIP_DEPO);
  const [odemeYapildi, setOdemeYapildi] = useState<EVET_HAYIR | undefined>();
  const [faturaOnay, setFaturaOnay] = useState<EVET_HAYIR | undefined>();
  const [efaturaMi, setEfaturaMi] = useState<EVET_HAYIR | undefined>();
  const [depoyeri, setDepoyeri] = useState<number | undefined>();
  const [pazaryeri, setPazaryeri] = useState<number | undefined>();
  const [kategori, setKategori] = useState<number | undefined>();
  const [marka, setMarka] = useState<number | undefined>();
  const [urun, setUrun] = useState<number | undefined>();
  const [siparisNo, setSiparisNo] = useState<string | undefined>();
  const [siparisId, setSiparisId] = useState<number | undefined>();
  const [seriNo, setSeriNo] = useState<string | undefined>();
  const [alisFaturaNo, setAlisFaturaNo] = useState<string | undefined>();
  const [faturaTipiId, setFaturaTipiId] = useState<number | undefined>();
  const [siparisTarihi, setSiparisTarihi] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>();
  const [satisTarihi, setSatisTarihi] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>();
  const [faturaTarihi, setFaturaTarihi] = useState<dayjs.Dayjs | null>();
  const [odemeTarih, setOdemeTarihi] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>();

  const [isPdf, setIsPdf] = useState(false);
  const [doc, setDoc] = useState<DokumanModel>();
  const [byteArray, setByteArray] = useState<string>();
  const [isDocModalOpen, setIsDocModalOpen] = useState(false);

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [list = [], loading, _getList] = API.ORDERS.useFetch({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const { selectedKeyList, selectedList, onMultiSelectClick } = useMultiSelect('siparisId', list);

  const columns: ColumnsType<SiparisModel> = createOrderColumns(faturaBekleyenler, onayBekleyenler, kullaniciModel.kullaniciTipi === ROL.ADMIN);

  const getList = useCallback(async () => {
    const filterData: SiparisModel = {
      firmaModel: kullaniciModel.firmaModel,
    };

    if (selectedUserId) {
      filterData.kullaniciModel = {
        kullaniciId: selectedUserId,
      };
    }

    if (depoyeri) {
      filterData.bhrDepoyeriModel = {
        depoyeriId: depoyeri,
      };
    }

    if (pazaryeri) {
      filterData.bhrPazaryeriModel = {
        pazaryeriId: pazaryeri,
      };
    }

    if (kategori) {
      filterData.bhrKategoriModel = {
        kategoriId: kategori,
      };
    }

    if (marka) {
      filterData.bhrMarkaModel = {
        markaId: marka,
      };
    }

    if (urun) {
      filterData.bhrUrunModel = {
        urunId: urun,
      };
    }

    if (siparisNo) {
      filterData.siparisNo = siparisNo;
    }

    if (siparisId) {
      filterData.siparisId = siparisId;
    }

    if (seriNo) {
      filterData.seriNo = seriNo;
    }

    if (alisFaturaNo) {
      filterData.alisFaturaNo = alisFaturaNo;
    }

    if (faturaTipiId) {
      filterData.bhrFaturaTipiModel = { faturaTipiId: faturaTipiId };
    }

    if (siparisStatu) {
      filterData.siparisStatu = siparisStatu;
    }

    if (odemeYapildi) {
      filterData.odemesiYapildi = odemeYapildi;
    }

    if (faturaOnay) {
      filterData.alisFaturasiOnaylandi = faturaOnay;
    }

    if (efaturaMi) {
      filterData.efaturaMi = efaturaMi;
    }

    if (siparisTarihi && siparisTarihi.length === 2) {
      const siparisTarihiStart = siparisTarihi[0].toISOString();
      const siparisTarihiTarihEnd = siparisTarihi[1].toISOString();

      filterData.siparisTarihiStart = siparisTarihiStart;
      filterData.siparisTarihiEnd = siparisTarihiTarihEnd;
    }


    if (satisTarihi && satisTarihi.length === 2) {
      const satisTarihiStart = satisTarihi[0].toISOString();
      const satisTarihiEnd = satisTarihi[1].toISOString();

      filterData.satisTarihiStart = satisTarihiStart;
      filterData.satisTarihiEnd = satisTarihiEnd;
    }

    if (faturaTarihi) {
      const faturaLastDay = faturaTarihi.daysInMonth();

      const faturaTarihiStart = faturaTarihi.date(1).toISOString();
      const faturaTarihiEnd = faturaTarihi.date(faturaLastDay).toISOString();

      filterData.faturaTarihiStart = faturaTarihiStart;
      filterData.faturaTarihiEnd = faturaTarihiEnd;
    }

    if (odemeTarih && odemeTarih.length === 2) {
      const tedarikciOdenecekTarihStart = odemeTarih[0].toISOString();
      const tedarikciOdenecekTarihEnd = odemeTarih[1].toISOString();

      filterData.tedarikciOdenecekTarihStart = tedarikciOdenecekTarihStart;
      filterData.tedarikciOdenecekTarihEnd = tedarikciOdenecekTarihEnd;
    }

    if (faturaBekleyenler) {
      let innerFilterData: SiparisModel = {
        firmaModel: kullaniciModel.firmaModel,

        alisFaturasiOnaylandi: EVET_HAYIR.HAYIR,
      };

      if (selectedUserId) {
        innerFilterData.kullaniciModel = {
          kullaniciId: selectedUserId,
        };
      }

      if (depoyeri) {
        innerFilterData.bhrDepoyeriModel = {
          depoyeriId: depoyeri,
        };
      }

      if (pazaryeri) {
        innerFilterData.bhrPazaryeriModel = {
          pazaryeriId: pazaryeri,
        };
      }

      if (kategori) {
        innerFilterData.bhrKategoriModel = {
          kategoriId: kategori,
        };
      }

      if (marka) {
        innerFilterData.bhrMarkaModel = {
          markaId: marka,
        };
      }

      if (urun) {
        innerFilterData.bhrUrunModel = {
          urunId: urun,
        };
      }

      if (siparisNo) {
        innerFilterData.siparisNo = siparisNo;
      }

      if (siparisId) {
        innerFilterData.siparisId = siparisId;
      }

      if (siparisTarihi && siparisTarihi.length === 2) {
        const siparisTarihiStart = siparisTarihi[0].toISOString();
        const siparisTarihiTarihEnd = siparisTarihi[1].toISOString();

        innerFilterData.siparisTarihiStart = siparisTarihiStart;
        innerFilterData.siparisTarihiEnd = siparisTarihiTarihEnd;
      }

      _getList(innerFilterData);
    } else if (onayBekleyenler) {
      let innerFilterData: SiparisModel = {
        firmaModel: kullaniciModel.firmaModel,
        alisFaturasiOnaylandi: EVET_HAYIR.HAYIR,
        faturaVarMi: true,
      };
      if (selectedUserId) {
        innerFilterData.kullaniciModel = {
          kullaniciId: selectedUserId,
        };
      }

      if (depoyeri) {
        innerFilterData.bhrDepoyeriModel = {
          depoyeriId: depoyeri,
        };
      }

      if (pazaryeri) {
        innerFilterData.bhrPazaryeriModel = {
          pazaryeriId: pazaryeri,
        };
      }

      if (kategori) {
        innerFilterData.bhrKategoriModel = {
          kategoriId: kategori,
        };
      }

      if (marka) {
        innerFilterData.bhrMarkaModel = {
          markaId: marka,
        };
      }

      if (urun) {
        innerFilterData.bhrUrunModel = {
          urunId: urun,
        };
      }

      if (siparisNo) {
        innerFilterData.siparisNo = siparisNo;
      }

      if (siparisId) {
        innerFilterData.siparisId = siparisId;
      }

      if (siparisTarihi && siparisTarihi.length === 2) {
        const siparisTarihiStart = siparisTarihi[0].toISOString();
        const siparisTarihiTarihEnd = siparisTarihi[1].toISOString();

        innerFilterData.siparisTarihiStart = siparisTarihiStart;
        innerFilterData.siparisTarihiEnd = siparisTarihiTarihEnd;
      }

      _getList(innerFilterData);
    } else {
      if (sahipsizler) {
        filterData.kullaniciModel = {
          kullaniciId: 201,
        };
      }
      _getList(filterData);
    }

    onMultiSelectClick([]);
  }, [
    selectedUserId,
    siparisStatu,
    odemeYapildi,
    faturaOnay,
    efaturaMi,
    kategori,
    marka,
    urun,
    depoyeri,
    pazaryeri,
    siparisTarihi,
    satisTarihi,
    faturaTarihi,
    odemeTarih,
    siparisNo,
    siparisId,
    seriNo,
    alisFaturaNo,
    faturaTipiId,
  ]);

  const pasif = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        await API.ORDERS.pasif(orders);
        getList();
      }
    },
    [getList]
  );

  const satis = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.satis(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const satisIptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.satisIptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );


  const iade = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.iade(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const iadeIptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.iadeIptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const hurda = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.hurda(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const hurdaIptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.hurdaIptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const downloadAll = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const ids: string[] = [];
        const files: {
          order: SiparisModel;
          file: string;
        }[] = [];

        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          if (order.alisFaturasiModel && order?.alisFaturasiModel?.dokumanId && !ids.includes(order?.alisFaturasiModel?.dokumanId)) {
            ids.push(order?.alisFaturasiModel?.dokumanId);

            const response = await API.FILES.getDocument(order?.alisFaturasiModel);
            if (response?.ok) {
              files.push({
                order,
                file: response.data,
              });
            }
          }
        }

        if (files.length > 0) {
          if (files.length > 1) {
            const zip = new JSZip();
            files.forEach((file) => {
              zip.file(file.order?.alisFaturasiModel?.dokumanAdi || '', file.file, { base64: true });
            });

            const blob = await zip.generateAsync({ type: 'blob' });

            saveAs(blob, 'Faturalar.zip');
          } else {
            const file = files[0];

            if (file?.order?.alisFaturasiModel) {
              downloadFile(file.order.alisFaturasiModel, file.file);
            }
          }
        }
      }
    },
    [getList]
  );

  const odemeYap = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.odemeYap(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const odemeGeriAl = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.odemeGeriAl(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const getDoc = useCallback(async () => {
    if (selectedList && selectedList.length > 0) {
      const selected = selectedList[0];
      setByteArray(undefined);
      setDoc(undefined);
      if (selected?.alisFaturasiModel) {
        const res = await API.FILES.getDocument(selected.alisFaturasiModel);

        let pdf = false;
        if (selected.alisFaturasiModel.dokumanAdi) {
          const filename = selected.alisFaturasiModel.dokumanAdi.split('.');
          const type = filename[filename.length - 1];

          pdf = type.toLowerCase() === 'pdf';
        }

        setIsPdf(pdf);
        setByteArray(res?.data);
        setDoc(selected.alisFaturasiModel);
        setIsDocModalOpen(true);
      }
    }
  }, [selectedList, setIsPdf, setDoc, setByteArray, setIsDocModalOpen]);

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  /*useEffect(() => {
    getList();
  }, [
    selectedUserId,
    siparisStatu,
    odemeYapildi,
    faturaOnay,
    efaturaMi,
    kategori,
    marka,
    urun,
    depoyeri,
    pazaryeri,
    siparisTarihi,
    satisTarihi,
    faturaTarihi,
    odemeTarih,
    siparisNo,
    siparisId,
    seriNo,
    alisFaturaNo,
    faturaTipiId,
  ]);*/

  useEffect(() => {
    getList();
  }, [
  ]);

  return {
    users,
    selectedUserId,
    setSelectedUserId,
    siparisStatu,
    setSiparisStatu,
    odemeYapildi,
    odemeGeriAl,
    setOdemeYapildi,
    faturaOnay,
    setFaturaOnay,
    efaturaMi,
    setEfaturaMi,
    depoyeri,
    setDepoyeri,
    pazaryeri,
    setPazaryeri,
    kategori,
    setKategori,
    marka,
    setMarka,
    urun,
    setUrun,
    siparisNo,
    setSiparisNo,
    siparisId,
    setSiparisId,
    seriNo,
    setSeriNo,
    alisFaturaNo,
    setAlisFaturaNo,
    faturaTipiId,
    setFaturaTipiId,
    siparisTarihi,
    setSiparisTarihi,
    satisTarihi,
    setSatisTarihi,
    faturaTarihi,
    setFaturaTarihi,
    odemeTarih,
    setOdemeTarihi,
    list,
    loading,
    getList,
    columns,
    selected,
    setSelected,
    onSelectClick,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    pasif,
    satis,
    satisIptal,
    iade,
    iadeIptal,
    hurda,
    hurdaIptal,
    odemeYap,
    downloadAll,
    isPdf,
    doc,
    byteArray,
    isDocModalOpen,
    setIsDocModalOpen,
    getDoc,
    isAdmin,
  };
};
